// Here we define translations for the component.

const translations = {
  title: {
    en_US: `Example`,
    de_DE: `Beispiel`,
    pl_PL: `Przykład`,
  },
  language: {
    en_US: `Language`,
    de_DE: `Sprache`,
    pl_PL: `Język`,
  },
  en_US: {
    en_US: `English`,
    de_DE: `Englisch`,
    pl_PL: `Angielski`,
  },
  de_DE: {
    en_US: `German`,
    de_DE: `Deutsch`,
    pl_PL: `Niemiecki`,
  },
  pl_PL: {
    en_US: `Polish`,
    de_DE: `Polnisch`,
    pl_PL: `Polski`,
  },
};

export default translations;
