// Here we define translations for the component.

const translations = {
  home: {
    en_US: `Home`,
    de_DE: `Startseite`,
    pl_PL: `Strona główna`,
  },
  career: {
    en_US: `Career`,
    de_DE: `Karriere`,
    pl_PL: `Kariera`,
  },
};

export default translations;
