// Here we define translations for the component.

const translations = {
  example: {
    en_US: `Example`,
    de_DE: `Beispiel`,
    pl_PL: `Przykład`,
  },
};

export default translations;
